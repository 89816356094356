body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.git-button {
  font-weight: 600;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

textarea, select, input, button, a { outline: none; }

@keyframes float {
	0% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    -webkit-transform: translatey(0px) perspective(100em) translateZ(-120px) rotateY(-10deg) rotateX(10deg);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		-webkit-transform: translatey(-20px) perspective(100em) translateZ(-120px) rotateY(-10deg) rotateX(10deg);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		-webkit-transform: translatey(0px) perspective(100em) translateZ(-120px) rotateY(-10deg) rotateX(10deg);
	}
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}